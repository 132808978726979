import { Column } from 'react-table'

import {
  Incident,
  IncidentActionType,
  IncidentEvent,
  IncidentStatus,
} from '@/graphql/generated/schemas'
import { formatTimeDistance } from '@/utils/formatDateTime'
import { getIncidentStatusDisplayName, isAIOperator } from '@/utils/incidents'

import { IncidentStatusOwner } from '../types/types'

export const IS_FLAGGED = 'isFlagged'

export const getTimeSinceDispatched = (incident: Incident): string => {
  const sortedAudioMessages = incident?.audioMessages?.edges.sort(
    (a, b) =>
      new Date(a?.node?.createdAt).valueOf() -
      new Date(b?.node?.createdAt).valueOf()
  )
  const dispatchTime =
    sortedAudioMessages && sortedAudioMessages[0]?.node?.createdAt
  const timeSinceDispatch = formatTimeDistance(dispatchTime)
  return timeSinceDispatch ? `Dispatched: ${timeSinceDispatch}` : ''
}

export const getIsReleased = (
  incident: Incident,
  hasGuard: boolean
): boolean => {
  return (
    incident?.actions?.some(
      (e) => e.type === IncidentActionType.ReleaseByGuard
    ) && !hasGuard
  )
}

export const filterByFlaggedColumn = (
  columns: Column<IncidentEvent>[]
): Column<IncidentEvent>[] => {
  return columns.filter((col) => col.accessor !== IS_FLAGGED)
}

export const getIncidentStatusWithAiOperator = (
  incidentStatusOwner: IncidentStatusOwner
): string => {
  const { status, owner } = incidentStatusOwner
  if (isAIOperator(owner)) {
    switch (status) {
      case IncidentStatus.InProgress:
        return 'AI In-Progress'
      case IncidentStatus.Resolved:
        return 'AI-Resolved'
      case IncidentStatus.Active:
        return 'Active'
      default:
        return 'Unknown'
    }
  }
  return getIncidentStatusDisplayName(status)
}
